const searchToolLimits = (function() {
    /**
     * Creates a hidden HTMLInputElement with a given name and value and adds it to the specified form.
     * @param form {HTMLFormElement} - HTML form to append the hidden field.
     * @param name {string} - Value for the name attribute.
     * @param value {string} - Value for the value attribute.
     */
    function addField(form, name, value) {
        const inputElmt = document.createElement("input");
        inputElmt.type = "hidden";
        inputElmt.name = name;
        inputElmt.value = value;
        form.append(inputElmt);
    }

    const pageInit = function() {
        // Only modify pages that have a searchtool form and are currently utilizing a Keyword (X), Author, Title,
        // or Subject (d) type search.
        if (!document.forms.searchtool || !["X", "a", "t", "d"].includes(document.forms.searchtool.searchtype.value)) {
            return;
        }

        const form = document.forms.searchtool;

        // Update the form to function like an Advanced Search form.
        form.action = "/search/X";
        form.searcharg.name = "search";
        // The searchtype dropdown is not supported by Advanced Search and is thus removed.
        if (form.searchtype.value !== "X") {
            form.searcharg.value = `${form.searchtype.value}:(${form.searcharg.value})`;
        }
        form.searchtype.value = "X";
        form.searchtype.style.display = "none";
        form.searchtype.removeAttribute("name"); // Having this attribute breaks Advance Search.
        // Add any missing search limits from the URL.
        const params = new URLSearchParams(window.location.search);
        // Location
        if (params.get("b")) {
            params.getAll("b").forEach((location) => addField(form, "b", location));
        }
        // Material Type
        if (params.get("m")) {
            params.getAll("m").forEach((materialType) => addField(form, "m", materialType));
        }
        // Language
        if (params.get("l")) {
            params.getAll("l").forEach((language) => addField(form, "l", language));
        }
        // Publisher
        if (params.get("p")) {
            params.getAll("p").forEach((materialType) => addField(form, "p", materialType));
        }
    }

    return {
        pageInit: pageInit
    };
})();

// Ensure the searchToolLimits.pageInit function runs when the page is completely loaded and ready and not before.
if (document.readyState === "complete" || document.readyState !== "loading") {
    searchToolLimits.pageInit();
} else {
    document.addEventListener("DOMContentLoaded", searchToolLimits.pageInit);
}
